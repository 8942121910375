
/** @jsx jsx */
import MySEO from '../../components/seo'
import {StaticImage} from 'gatsby-plugin-image'
import {Box,jsx,Flex,Text,Heading} from 'theme-ui'
import Layout from '../../components/layout'

export default function DritterSalon (){
    return(
        <Layout>
      

 <Flex sx={{m:3,flexDirection:"column"}}>

 <MySEO title="Konzert im Kulturhaus Oberwesel" description = "Konzert im Kulturhaus Oberwesel " />


<Box sx={{maxWidth:600}}>

<StaticImage src="../../images/veranstaltungen/Alarcon_Reiter-2.jpg" layout="constrained" width={550} 
alt="Bild von Benjamin Reiter &amp; Ana Paola Alarcón"  />
<Text variant="photo">Benjamin Reiter, Ana Paola Alarcón</Text>

</Box>

  <Heading as="h1" sx={{
                     fontWeight:500,
                     fontSize:[2,3],
                     mt:4
                 }}>Salon N° 3
          <br/>
          Muy apasionado
          
          <span style={{fontSize:20,padding: '20px 0',fontWeight:500, paddingBottom:0,display:"block"}}>Sonntag, 11. Dezember,  16:30 Uhr<br/>
Ort: Kulturhaus Oberwesel<br/>
Einlass: ab 16:00, Eintritt: 15,- €
          </span>    
          </Heading>
<Box sx={{pr:2}}>

<Heading></Heading>
<Text variant="absatz">
Lassen Sie sich kurz vor Weihnachten noch einmal musikalisch verführen mit <span style={{fontWeight:500}}>Ana Paola Alarcón (Violine)</span> und <span style={{fontWeight:500}}>Benjamin Reiter (Klavier)</span>. </Text>
<Text variant="absatz">In ihrem abwechslungsreichen Programm im Kulturhaus Oberwesel bauen sie <span style={{fontStyle:'italic'}}>eine Brücke von der traditionellen deutschen Klassik über die französisch-belgische Spätromantik bis zur zeitgenössischen klassischen Musik Lateinamerikas</span>.
</Text>
<Text variant="absatz">
Der gebürtige Bacharacher Benjamin Reiter und Ana Paola Alarcón präsentieren ein Repertoire von großer Spannbreite: </Text>
<Text variant="absatz">Die Sonate D-Dur Op. 12 Nr. 1 von <span style={{fontWeight:600}}>Ludwig van Beethoven</span> ist eine stets frisch wirkende Eröffnung, während die große Violinsonate von <span style={{fontWeight:600}}>Caesar Franck </span>eine vielfältige Klangwelt zwischen verträumt-atmosphärischen Flächen und höchster Dramatik eröffnet.
</Text>  
<Text mb="3" variant="absatz">
Mit dem zeitgenössischen, ursprünglich als Bühnenmusik zum gleichnamigen Theaterstück konzipierten Werk „Diogenes y las camisas voladoras“ des <span style={{fontWeight:600}}>venezolanischen Komponisten Icli Zitella</span>, den Ana Paola Alarcón aus ihrer Heimatstadt Caraccas persönlich kennt, wird ein spannungsvoller Kontrast gesetzt. 
</Text>


    <a rel="noopener noreferrer" href="https://www.ticket-regional.de/events_info.php?eventID=193543"><Text sx={{color:'text',fontSize:1,padding:'20px 0'}}>&rarr; Online-Ticket bestellen</Text></a>


<Text mb="4" mt="2" >
Die Veranstaltung findet in Kooperation mit dem Kulturnetz Oberes Mittelrheintal (K.O.M.) e.V. statt. 
</Text>
</Box>

</Flex>


</Layout>

    )
}



